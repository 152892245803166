import axios from 'axios';
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';
import API from '@/config';

/** Default config for axios instance */
// const API_ENDPOINT = API.api
const API_ENDPOINT = API.api_dotcom

let config = {
    baseURL: `${API_ENDPOINT}`,
    auth: {
        username: 'api@admin.com',
        password: 'IT_api'
    }
};

/** Creating the instance for axios */
const httpClient = axios.create(config);

const authService = {
    currentUserData: [],
    // login to generic api-user 
    async login() {
        if (!sessionStorage.getItem('token')) {
            try {
                const { status, data } = await httpClient.get(`/user-api/login`)
                if (status === 200) {
                    sessionStorage.setItem('token', data.token)
                    return data.token
                }
            } catch (e) {
                console.log(e)
                return {
                    success: false,
                    errors: e.response.data.errors.password[0]
                }
            }
        }
    },
    async logout() {        
        try {
            // Llamando al API para desloguearse //     
            const res = await this.checkLogin(`/clients/logout`, {auth: 'bearer'})
            sessionStorage.removeItem('token') 
            if(res.status == 200) {
                sessionStorage.setItem('token', res.data.token)              
                authService.destroyLocalUserData()
            }
        } catch(e) {                
            console.log('problemas al desloguearse')
            console.log(e)
        }        
    },
    isClientLogged() {
        if (sessionStorage.getItem('token')) {
            const token = jwt_decode(sessionStorage.getItem('token'))
            return (token.client) ? true : false
        }
        return false
    },
    async checkLogin(url, paramsData = {}, method = 'get') {
        // Check if we are logged in the API
        const request_config = {
                url,
                method
            },
            token = sessionStorage.getItem('token')

        if (paramsData.data) request_config.data = paramsData.data
        if (paramsData.params) request_config.params = paramsData.params        
        let headers = paramsData.headers ? paramsData.headers : {};
        
        if (sessionStorage.getItem('token')) {
            if (paramsData.auth == 'bearer') {
                // request_config.headers = {
                //   Authorization: 'Bearer '+ token
                // }              
                let req = null;
                if (method == 'get')
                    req = await this.getAxiosToken(token, config.baseURL + url,headers)
                else
                    req = await this.otherAxiosToken(token, config.baseURL + url, paramsData, method)
                
                // req                
                if(req.error == 'Unauthorized') {
                    this.router.push('/')  
                }
                return req
            }
            return httpClient.request(request_config).catch(err => {
                    return err.response
                })
                // httpClient.interceptors.request.use(conf)
        } else  
            throw new Error('Something bad happened.');

    },
    async checkLoginImage(url, paramsData = {}, method) {
        // Check if we are logged in the API        
        const token = sessionStorage.getItem('token')
        // console.log(paramsData)
        if (sessionStorage.getItem('token')) {            
            let req = null;
            req = this.otherAxiosToken(token, config.baseURL + url, paramsData, method)
            return req
        }    
        this.$router.push('/')      

    },
    async getAxiosToken(token, url,headers = {}) {        

        if(!headers)
            return axios.get(url, { headers: { Authorization: 'Bearer ' + token } }).then(resp => {
                return resp
            }).catch(err => {
                return err
            })

        // Hay headers
        let newHeaders = { ...headers, Authorization: 'Bearer ' + token };        
        return axios.get(url, {headers: newHeaders}).then(resp => {
            return resp
        }).catch(err => {
            return err
        })
    },
    async otherAxiosToken(token, url, params, method) {
        try {
            const result = (method == 'post') ?
                await axios.post(url, params, { headers: { Authorization: 'Bearer ' + token } }) :
                await axios.put(url, params, { headers: { Authorization: 'Bearer ' + token } })
            return result
        } catch (e) {            
            return e
        }
    },    
    getClientToken() {
        return this.decodeToken(sessionStorage.getItem('token'))
    },
    decodeToken(token) {
        return jwt_decode(token)
    },
    createLocalUserData() {
        const client = this.getClientToken().client
        localStorage.setItem('id', client.id)
        localStorage.setItem('user', client.name)
        localStorage.setItem('roles', client.roles)
        localStorage.setItem('email', client.email)
    },
    destroyLocalUserData() {
        localStorage.clear()
    },
    validateRole(roles, exclusive=false) {
        if (this.isClientLogged()) {
            const current_roles = localStorage.getItem('roles')                                    
            
            if (!exclusive) {
                if(current_roles.includes('ROLE_SuperAdmin')){
                    return true
                }
            } 
            
            if(roles != undefined) {
                for(let r of roles){                
                    if(current_roles.includes(r))
                        return true
                }
            }
        } else {
            // Client not logued
            this.destroyLocalUserData()
            return false
        }
        return false
    },
    encryptPass(pass) {
        const decodeToken = this.getClientToken()
        const token = sessionStorage.getItem('token').slice(-10)
        let encrypted_pass = null        
        let salt = CryptoJS.lib.WordArray.random(128 / 8);        

        if (token) {
            encrypted_pass = CryptoJS.AES.encrypt(pass, token, salt);
            // console.log(encrypted_pass.toString())
            // console.log('token',sessionStorage.getItem('token'))
        }        
        return encrypted_pass.toString()
    },

}

export default authService
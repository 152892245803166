import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Admin",
    component: () =>
    import("@/views/Admin.vue")
  },
  
  {
    path: "/Login",
    name: "Login",
    component: () =>
    import("@/views/LoginForm.vue")
  },
 
  // Admin
  {
    path: "/dashboard",
    name: "Dashboard",    
    component: () =>
      import("@/views/Admin.vue"),         
  },
  {
    path: "/admin/:CrudKey",
    name: "CrudKey",
    component: () =>
      import("@/views/Admin.vue"),    
  },    
  {
    path: "/admin/prices/:CrudKey",
    name: "AdminPrices",
    component: () =>
      import("@/views/Admin_Prices.vue"),    
  },    

  // 404
  {
    path: '/:catchAll()',
    redirect: '/'
  }
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

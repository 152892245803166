//
//
//
//
//
//
//

import Footer from "@/components/Footer.vue";
import Authorization from "@/services/user/auth.service";

export default {
  components: {
    Footer
  },  
  data() {
    return {
      showMobileMenu: false,
    };
  },  
  created() {
    this.loginAPI()    
    if (!Authorization.isClientLogged()) {
      this.$router.push({name:'Login'})     
    }
  },
  methods: {
    async loginAPI() {
        await Authorization.login()          
    },
  },
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/tailwind.css";

import SmartTable from 'vuejs-smart-table'
// FONTAWESOME ICONS
import "@fortawesome/fontawesome-free/css/all.css";

Vue.config.productionTip = false;

Vue.use(SmartTable)

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
